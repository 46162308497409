<template>
  <div class="card-tm">
    <div class="container container-row">
      <div class="row gy-3">
        <div class="col-md-12">
          <TopHeading :data="topHeading" />
        </div>
        <div class="col-12">
          <div class="row align-items-center gy-4">
            <div class="col-12 col-lg-5">
              <div class="card rounded-4">
                <div class="card-body">
                  <div class="row g-3">
                    <div class="col-6">
                      <label class="fs-14 pb-2 fw-500">Select Coin</label>
                      <v-select class="border-0" v-on:input="assignSymbol($event.title)" v-model="selected"
                        :options="pairs" label="title">
                        <template slot="selected-option" slot-scope="option">
                          <div class="d-flex align-items-center">
                            <img v-if="selected.img" height="20" v-bind:src="option.img">
                            <img v-else height="20" v-bind:src="coin_img" />
                            <span style="position: relative; margin: 0px 5px">{{ option.title }}</span>
                          </div>
                        </template>
                        <template v-slot:option="option">
                          <img :src="option.img" style="width:20px; margin-right:8px;">{{ option.title }}
                        </template>
                      </v-select>
                    </div>
                    <div v-if="loading" class="col-12 text-center mb-2">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div class="col-6" v-if="dataVisibleWithdraw">
                      <label class="fs-14 pb-2 fw-500">Select Network</label>
                      <v-select :clearable="false" id="token" label="token_type" v-model="form.token_type"
                        :searchable="false" v-on:input="alterTokenName($event)" :reduce="(option) => option.token_type"
                        :options="withdraw_net"></v-select>
                    </div>
                    <div class="col-12" v-if="dataVisibleWithdraw">
                      <label class="fs-14 pb-2 fw-500">Destination Address</label>
                      <div class="form-main-box" :class="{
                            'form-group--error': $v.form.toAddress.$error
                          }">
                        <input type="text" class="form-control" id="formGroupExampleInput"
                          placeholder="Enter Wallet Address" autocomplete="off" v-model.trim="$v.form.toAddress.$model" />

                        <div class="error" v-if="
                              submitted && !$v.form.toAddress.required
                            ">
                          Destination is required.
                        </div>
                      </div>
                      <div>
                        <span class="text-primary fs-12 pb-2 fw-500">Transaction Fees:</span>
                        <span class="fw-500 fs-12"> {{ this.withdraw_commission }}
                          <span v-if="withdraw_commission_per">%</span></span>
                      </div>
                    </div>
                    <div class="col-12" v-if="dataVisibleWithdraw">
                      <label class="fs-14 pb-2 fw-500">Amount</label>
                      <input type="text"
                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                        onpaste="return false;" class="form-control" id="formGroupExampleInput" placeholder="Amount"
                        v-model.trim="$v.form.amount.$model" autocomplete="off" />
                      <div class="error text-start" v-if="submitted && !$v.form.amount.required">
                        Amount is required.
                      </div>
                      <div class="error text-start" v-if="submitted && between">
                        Amount should be less or equal to your
                        balance
                      </div>
                      <div class="text-start">
                        <span class="fw-500 fs-12"> {{ this.withdrawModal.balance }} </span>
                        <span class="text-primary fs-12 pb-2 fw-500"> {{ this.withdrawModal.itemCurrrency }}</span>
                      </div>
                    </div>
                    <div class="withdraw-btn-box py-2" v-if="dataVisibleWithdraw">
                      <button v-if="withdraw_loading" type="button" class="btn btn-primary main-btn"
                        data-bs-target="#proceedwithdrawl">
                        <div class="spinner-border text-light" role="status"></div>
                      </button>
                      <button v-else type="button" class="btn btn-primary p-2 w-100 main-btn"
                        data-bs-target="#proceedwithdrawl" @click="submitWithdrawal">
                        Proceed Withdraw
                      </button>
                    </div>
                    <div v-else class=" alert alert-warning text-center">
                      <span class="">{{this.withdrawModal.message}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-5 offset-lg-1" v-show="dataVisibleWithdraw">
              <h6 class="fw-600 fs-20">Warnings</h6>
              <ul class="list">
                <li class="warning-dis py-1">1. Please Double-Check Destination Address.
                  Withdrawal Requests Cannot Be Cancelled After
                  Submission.</li>
                <li class="warning-dis py-1 ">2. Withdrawals To Smart Contract Addresses Will
                  Be Lost Forever.</li>
                <li class="warning-dis py-1 ">3. After your request has been filed, you will need to confirm
                  the network node. After the 30 network confirmation, it will be credited.
                  Once all 30 network confirmations are approved, you are allowed to withdraw your assets.
                </li>
                <li class="warning-dis py-1 ">4. Your wallet address will not change. However, if it does, you
                  are advised to file an inquiry. If there are any changes, we will notify you through our
                  website announcements or via email.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 py-3">
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="fs-5 fw-700">Withdraw History</h4> -->
              <WithdrawHistory />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import { required } from "vuelidate/lib/validators";
import "vue-select/dist/vue-select.css";
import ApiClass from "@/api/api";
import exactMath from "exact-math";
import TopHeading from "@/components/ui/TopHeading.vue";
import WithdrawHistory from "@/components/Wallet/WithdrawHistory.vue";
export default {
  name: "withdraw",
  components: {
    TopHeading,
    WithdrawHistory
  },

  data: () => ({
    topHeading: {
      heading: 'Withdrawals',
      subheading: 'Crypto available for withdrawal',
      buttons: [
      { name: 'Transactions History', link: '/wallet/transaction-history', class: 'text-primary border-primary' },
        // {name:'Deposit', link:'/wallet/deposit', class:'btn-primary text-white'}
      ],
    },
    selected: "Choose Coin",
    coin_img: null,
    pairs: [],
    withdraw_loading: false,
    form: {
      // token_types: [],
      token_type: "",
      amount: "",
      toAddress: "",
      currency: "",
    },
    withdraw_commission: "",
    withdraw_commission_per: false,
    withdraw_type: "",
    currency_networks: [],
    withdraw_net: [],
    showData: [],
    withdrawl_data: "",
    submitted: false,
    between: false,
    withdrawModal: {
      itemCurrrency: "",
      itemWithdraw: "",
      message: "",
      token_type: "",
      balance: "",
      toAddress: "",
    },

    base_currency: [],
    other_currency: [],
    total_currency: "",
    response: [],
    loading: true,
    showData1: [],
    dataVisibleWithdraw: false,
    total_freezed: null

  }),
  validations() {
    // var blnc = this.withdrawModal.balance? this.withdrawModal.balance : 0;
    return {
      form: {
        toAddress: {
          required,
        },
        // token_type: { required },
        amount: {
          required,
        },
      },
    };
  },
  async mounted() {

    this.querySymbol = this.$route.query.symbol;

    if (this.$route.query.symbol) {
      this.selected = this.querySymbol;
      await this.assignSymbol(this.$route.query.symbol);
    }
    await this.callCoinsApi();
    await this.callApi();

  },

  computed: {
    total_currency_get() {
      let total = 0;
      this.other_currency?.map((el) => {
        let s = exactMath.mul(exactMath.add(el.quantity, el.freezed_balance), el.c_price);
        total = s + total;
      });
      return total.toFixed(4);
    },

  },
  methods: {
    alterTokenName(token_type) {
      if ((this.withdrawModal.itemCurrrency == 'ETH') &&
        token_type == 'SELF') {
        return 'ERC20';
      }
      if ((this.withdrawModal.itemCurrrency == 'TRX') &&
        token_type == 'SELF') {
        return 'TRC20';
      }
      if ((this.withdrawModal.itemCurrrency == 'MCOIN') &&
        token_type == 'SELF') {
        return 'MCOIN20';
      }
      return token_type;

    },
    networkInfo(tType = null) {
      tType = tType?.substring(0, 3);
      if (tType == "ERC") return { name: "Ethereum", short: "ERC" };
      else if (tType == "TRC") return { name: "Tron", short: "TRC" };
      else if (tType == "BEP") return { name: "Binance", short: "BEP" };
      else if (tType == 'SEL' && this.depositModal.itemCurrrency == "MCOIN") return { name: "MCOIN", short: "MCOIN", token_type: "MCOIN20" };
      else if (tType == 'SEL' && this.depositModal.itemCurrrency != "MCOIN") {
        return (this.depositModal.itemCurrrency == 'ETH') ? { name: "Ethereum", short: "ERC", token_type: "ERC20" } : { name: "Tron", short: "TRC", token_type: "TRC20" };
      }
      else return { name: tType, short: tType };
    },
    async assignSymbol(event) {
      if (event != this.$route.query.symbol) {
        this.$router.push({ path: this.$route.fullPath, query: { symbol: event } });

      }
      await this.callApi(event);
      this.withdraw(this.other_currency[0]);

    },

    assignWithdraw(data = null) {
      // console.log("withdraw======", data);
      this.showData1 = data;
      this.submitted = false;
      this.withdraw_commission_per = (data.type == "percentage") ? true : false;
      this.withdraw_commission = data.withdraw_commission;
      this.form.token_type = data.token_type;
      this.form.amount = this.form.toAddress = "";
    },
    hi() {
      this.currency_networks = this.withdrawl_data.filter((e) => {
        if (this.form.token_type == e.token_type) {
          return e;
        }
      });

      let arr = this.currency_networks[0];
      this.withdraw_commission =
        //  arr.type == "percentage"
        //   ? (arr.withdraw_commission * 10 * (this.form.amount * 10)) / 100
        //   : arr.withdraw_commission;
        arr.type == "percentage"
          ? (arr.withdraw_commission * this.form.amount) / 100
          : arr.withdraw_commission;
      // console.log("checkk", this.withdraw_commission)
    },


    async callApi(symbol = null) {
      this.response = await ApiClass.getNodeRequest("user-crypto/get", true);
      if (this.response.data.status_code == 1) {
        this.loading = false;
        this.base_currency = this.response.data.data.filter((e) => {
          if (e.currency_type == "fiat" && e.symbol == symbol) {
            return e;
          }
        });

        this.other_currency = this.response.data.data.filter((e) => {
          if (e.currency_type == "crypto" && e.symbol == symbol) {
            return e;
          }
        });

        this.response.data.data.filter((e) => {
          if (e.symbol == symbol) {
            this.coin_img = e.image;
          }
        });

      }
      // console.log(this.response);
    },
    async callCoinsApi() {
      this.response = await ApiClass.getNodeRequest("user-crypto/get", true);
      // console.log(this.response);
      if (this.response.data.status_code == 1) {
        this.response.data.data.filter((e) => {
          if (e.withdraw_enable == true) {
            this.pairs.push({ "title": e.symbol, 'img': e.image });

          }
        });
        this.total_currency = this.response.data.mainTotal;
        this.total_freezed = this.response.data.freezedTotal;

      }
    },


    withdraw(item) {
      this.withdrawModal.itemCurrrency = item.symbol;
      this.withdrawModal.itemWithdraw = item.withdraw_enable;
      this.withdrawModal.message = item.withdraw_desc;
      this.form.currency = item.symbol;
      this.dataVisibleWithdraw = item.withdraw_enable;
      this.withdrawModal.balance = item.quantity;

      if (this.dataVisibleWithdraw) {
        this.withdraw_net = item.currency_networks.filter(
          (e) => e.withdraw_enable == true
        );

        if (this.withdraw_net.length != 0) {
          this.showData1 = this.withdraw_net[0];
          //  console.log(this.showData1);
          this.withdrawModal.toAddress = this.withdraw_net[0].address;
          this.form.token_type = this.withdraw_net[0].token_type;
          this.withdraw_commission_per = this.withdraw_net[0].type == 'percentage' ? true : false;
          this.withdraw_commission = this.withdraw_net[0].withdraw_commission;
          //   console.log(this.form);
          // this.form.token_types = abc.map((e) => {
          //   if (e.withdraw_enable) {
          //     return e.token_type;
          //   }
          // });
          // this.form.token_type = this.form.token_types[0];
          this.withdrawl_data = item.currency_networks;
        } else {
          this.dataVisibleWithdraw = false;
        }
      }
    },

    async submitWithdrawal() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      console.log("hi");

      if (
        parseFloat(this.form.amount) > parseFloat(this.withdrawModal.balance) ||
        this.form.amount == "0"
      ) {
        this.between = true;
        return;
      }
      this.submitted = false;
      this.withdraw_loading = !this.withdraw_loading;
      // console.log("form =====", this.form);
      var result = await ApiClass.postRequest(
        "block/transfer",
        true,
        this.form
      );
      this.withdraw_loading = !this.withdraw_loading;
      if (result.data.status_code == 1) {
        this.form.amount = "";
        this.form.toAddress = "";
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        return this.$router.push({
          name: "Verify",
          params: {
            data: result.data.data,
          },
        });
      } else {
        // this.loading = false;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

  },
};
</script>
  
<style scoped>
.nav {
  gap: 20px;
}

.nav-pills .nav-link {
  border: 1px solid var(--bs-gray-400);
}

.deposit-crypto {
  min-height: 40vh;
}

.deposit-crypto .page-title {
  padding: 10px;
  background: transparent;
}

.page-title .heading {
  margin-bottom: 0 !important;
  font-size: 24px !important;
}

.portfolio-transaction {
  padding: 0 !important;
}

.backbutton {
  display: none;
}

.list li {
  font-size: 13px;
  line-height: 25px;
}

.fa-circle-exclamation {
  color: rgb(255, 71, 71);
}

.is_dark .nav-pills .nav-link {
  border: 1px solid var(--bs-gray-600);
  color: #fff;
}

.vs__dropdown-toggle {
  padding: 5px;
  border: 1px solid var(--bs-gray-400);
}
</style>