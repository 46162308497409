<template>
  <div class="table-container table-height table-responsive custom-scrollbar">
    <table class="table table-borderless align-middle" id="table-list">
      <thead class="">
        <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
          <th scope="col" v-for="(item, index) of tableHeading" :key="index">{{
            item.name
          }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="skeletonLoad">
          <tr class="align-middle" v-for="i in tableHeading.length" :key="i">
            <td class="text-uppercase" v-for="j in tableHeading.length" :key="j">
              <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd" wave-color="#fff"
                :rounded="true" />
            </td>
          </tr>
        </template>
        <template v-if="TransactionList.length >= 1 && !skeletonLoad">
          <tr class="border-0" v-for="(translist, index) in TransactionList" :key="index">
            <td>
              <div class="d-flex gap-2 align-items-center">
                <!-- <img src="https://tradebit.io/backend/public/currency_images/tether.png" width="23"> -->
                <h6 class="fs-15 m-0 fw-500">{{ translist.currency }}</h6>
              </div>
            </td>
            <td>
              <h6 class="subtitle">{{ translist.chain_type }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ translist.amount }}</h6>
            </td>
            <td>
              <h6 v-if="isNaN(translist.extra)" class="subtitle">{{ translist.extra }}</h6>
              <a v-else
                :href="(translist.chain_type == 'TRX' ? trx : (translist.chain_type == 'BSC' ? bsc : eth)) + blockHash(translist.transfer_detail)">{{
                  blockHash(translist.transfer_detail).substring(0, 40) + "..."
                }}</a>
            </td>
            <td>
              <a class="subtitle"
                :href="(translist.chain_type == 'TRX' ? trxAdd : (translist.chain_type == 'BSC' ? bscAdd : ethAdd)) + translist.to_address">{{
                  translist.to_address.substring(0, 22) + "..."
                }}</a>
            </td>
            <td>
              <span class="subtitle text-capitalize">{{ translist.status }}</span>
            </td>
            <td>
              <h6 class="subtitle">
                {{
                  new Date(translist.created_at).toLocaleDateString(
                    "en-US",
                    options
                  )
                }}
              </h6>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="col-12 text-center" v-if="loading && !skeletonLoad">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="TransactionList.length < 1 && !skeletonLoad"
      class="d-flex border-0 flex-column text-center justify-content-center py-4">
      <div>
            <img width="70" :src="require(`../../assets/images/icons/no-record.svg`)" />
          </div>
      <span class="fw-500 fs-14">No record found</span>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";

import ApiClass from "@/api/api";
export default {
  name: "WithdrawHistory",
  components: {
    //   Heading,
    VueSkeletonLoader
  },
  data() {
    return {
      trx: "https://tronscan.org/#/transaction/",
      eth: "https://etherscan.io/tx/",
      bsc: "https://bscscan.com/tx/",
      trxAdd: "https://tronscan.org/#/address/",
      ethAdd: "https://etherscan.io/token/",
      bscAdd: "https://bscscan.com/address/",
      firstload: true,
      loading: false,
      search: "",
      updateRes: [],
      skeletonLoad: true,
      tableHeading: [
        { name: 'Coin' },
        { name: 'Network' },
        { name: 'Amount' },
        { name: 'Block Hash' },
        { name: 'Address' },
        { name: 'Status' },
        { name: 'Date and Time' }
      ],

      TransactionList: [],
      options: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
    };
  },
  async mounted() {
    await this.callApi();
  },
  methods: {
    searchData() {
      var search = this.search.toUpperCase();
      if (search) {
        this.TransactionList = this.updateRes.filter((e) => e.symbol.includes(search));
      } else {
        this.TransactionList = this.updateRes.filter((e) => e);
        // this.searchZeroData();
      }
    },
    async callApi() {
      var response = await ApiClass.getRequest("wallet-trans/get", true);
      if (response.data.status_code == 1) {
        console.log(response);
        this.loading = false;
        this.skeletonLoad = false;
        this.TransactionList = this.updateRes = response.data.data;
      }
    },
    blockHash(data = null) {
      if (data == null) {
        return "NULL";
      }
      return (data.transactionHash) ? data.transactionHash : data.txid;
    }
  }
};
</script>

<style scoped>
.success-badge {
  color: #14B8A6;
  background-color: #CCFBF1;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
}

.danger-badge {
  color: #EF4444;
  background-color: #FEE2E2;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
}

.table>:not(:first-child) {
  border-top: 1px solid var(--bg);
}

#table-list tbody td .subtitle {
  color: var(--text);
}
</style>